$variation-attribute-size-font-size: 14px;
$variation-attribute-label-font-size-desktop: 16px;
$variation-attribute-label-font-size-mobile: 14px;
$swatch-circle-size: 25px;
$size-chart-font-size-desktop: 14px;
$size-chart-font-size-mobile: 12px;

.modal-dialog.quick-view-dialog {
  .modal {
    &-header {
      background-color: $background;
      border-bottom: 1px solid $medium-light-gray;
      align-items: center;

      a {
        color: $custom-link-color;
        text-decoration: underline;
        font-size: 14px;
        font-family: $main-font;
      }

      .full-pdp-link {
        color: $custom-link-color;
        font-size: 1.25rem;
      }

      .close {
        line-height: 1rem;
        color: #999;
        margin: 0;
        padding: 0;
        display: flex;

        &:focus{
          outline: none;
        }

        img.close-img{
          background: url(../images/Icon_x.svg) no-repeat;
          margin: 0;
          background-size: 16px;
          padding: 8px;
        }
      }
    }

    &-body {
      padding: 30px 24px;

      .quickview-islimited {
        max-width: 99%;
      }

      .card-stamps-limitedProduct {
        .quickview-face {
          .face {
            width: 6%;
            margin-bottom: 2px;
          }
        }
      }

      .col-sm-6 {
        padding-left: 15px;

        &:first-child {
          padding-right: 15px;
        }
      }

      .carousel {
        &-indicators {
          margin-bottom: 0;
        }
      }

      .product-name {
        min-height: 1.5rem;
        font-size: 24px;
        font-family: $main-font;
        font-weight: 500;
      }

      .stamps-and-product-id-container {
        display: flex;
        flex-direction: column;

        @include respond-to-max-width($max-mobile-width) {
          flex-direction: column-reverse;
        }
      }

      .clickandcollect-messsage {
        color: #999;
        text-align: left;
        font-size: 12px;
      }

      .fa-exclamation-circle {
        color: #fda806;
        transform: rotate(180deg);
        vertical-align: middle;
      }

      .big-volume {
        &-tag {
          color: #fda806;
          text-align: left;
          text-decoration: underline;
          font-size: 14px;
        }

        &-message {
          color: #999;
          text-align: left;
          font-size: 12px;
        }

        &-product {
          margin-bottom: 12px;
        }
      }

      .technological-product {
        margin-bottom: 5px;
        padding: 5px 0;

        .tech-product {
          &-title {
            .fa-exclamation-circle {
              color: #2e3092;
              transform: rotate(180deg);
              vertical-align: middle;
            }
          }

          &-message {
            color: #999;
            text-align: left;
            font-size: 12px;
            margin-left: 5%;

            .fa-exclamation-circle {
              color: #2e3092;
              transform: rotate(180deg);
              vertical-align: middle;
            }
          }

          &-tag {
            color: #2e3092;
            text-align: left;
            text-decoration: underline;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }

      .non-tech-product {
        margin-top: 8px;
        margin-bottom: 5px;

        .fa-exclamation-circle {
          color: #2e3092;
          transform: rotate(180deg);
        }

        &.non-tech-product-message {
          color: #999;
          text-align: left;
          font-size: 12px;
          padding-top: 5px;

          a.tech-product-tag-text {
            color: #2e3092;
            text-align: left;
            text-decoration: underline;
            font-size: 12px;
            font-weight: 600;
          }
        }
      }

      .product-id {
        color: $primary-color-2;
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 15px;
      }

      .prices {
        text-align: left;
      }

      .detail-panel {
        border-top: 1px solid $light-gray;

        .attributes {
          display: flex;
          flex-flow: column;
          border-top: 1px solid #cacaca;
          padding-top: 18px;
          padding-left: 0;
          padding-right: 0;

          > .row:first-child {
            display: none;
          }

          [data-attr="color"] {
            order: -1;
          }

          > span {
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: 500;
          }

          .attribute {
            padding-bottom: 15px;
            margin-bottom: 0;
            margin-top: 15px;
            border-bottom: 1px solid $light-gray;

            .color-attribute {
              margin-right: 10px;

              .swatch-circle {
                width: 25px;
                height: 25px;

                &.selected::after {
                  font-size: inherit;
                }
              }
            }

            ul {
              &.attribute-size {
                padding-inline-start: 0;
                display: flex;
                flex-wrap: wrap;
                margin: -5px;
              }

              .variation-attribute-size {
                font-size: 18px;
                width: 84px;
                height: 46px;
                border-radius: 3px;
                margin: 2px;
                border: 1px solid #121212;
                color: #121212;
                display: flex;
                flex: 0 1 84px;

                &.disabled {
                  border-color: #cacaca;
                  color: #cacaca;
                }

                &.selected {
                  color: white;
                  background-color: $primary-color-2;
                }

                span {
                  padding-bottom: 1px;
                  padding-left: 5px;
                  padding-right: 5px;
                }

                &-values {
                  display: flex;
                  flex-wrap: wrap;
                  margin: -5px;
                  margin-left: 2px;
                }

                @include respond-to-max-width(991px) {
                  width: 76px;
                  flex: 0 1 76px;
                }
              }

              .size-chart {
                display: none;
              }

              li {
                font-size: $variation-attribute-size-font-size;
                margin: 5px;
                cursor: pointer;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                  font-family: $main-font;
                  font-weight: $font-weight-regular;
                }

                &.selected {
                  border: 1px solid $primary-color-2;
                  cursor: not-allowed;
                  pointer-events: none;
                }

                &.disabled {
                  color: $medium-light-gray;
                  cursor: not-allowed;
                  pointer-events: none;
                }
              }
            }
          }
        }
      }

      .button-container {
        margin-top: 15px;

        .row.cart-and-ipay {
          .update-cart-product-global {
            background-color: $primary-color-2;
            border-color: $primary-color-2;
            font-family: $main-font;
            font-weight: bold;
            font-size: 15px;
            height: 45px;
          }

          @include respond-to-max-width($max-mobile-width) {
            padding-bottom: 0;
          }
        }

        button {
          width: 100%;
          color: $background;
          background-color: $secondary-color-1;
          border-color: $secondary-color-1;
          font-family: $main-font;
          font-weight: bold;
        }
      }

      @import "./stamps";

      @include respond-to-max-width($max-mobile-width) {
        max-height: unset;
      }
    }

    &-footer {
      display: none;
    }
  }
}
