@import "./cartHeader";

.cart {
  &-page {
    padding-top: 70px;
    min-height: 60vh;

    .promo-code-form {
      display: block;
    }

    .card {
      border: none;

      .product-image {
        border: 1px solid #cacaca;

        @include respond-to-max-width(991px) {
          width: 110px;
          max-width: 110px;
          height: 110px;
          max-height: 110px;
        }
      }

      .col-4.d-md-none {
        @include respond-to-max-width(991px) {
          max-width: 110px;
        }
      }
    }

    .totals {
      font-family: $main-font;

      .checkout-continue {
        padding-right: 7px;
        padding-left: 7px;

        @include respond-to-max-width(543.98px) {
          div {
            padding-left: 0;
            padding-right: 0;
          }
        }

        @include respond-to-max-width($max-mobile-width) {
          background-color: transparent;
          box-shadow: none;

          &.cart-button-promotion {
            position: relative;
          }
        }
      }
    }

    @include respond-to-between(544px, 991px) {
      margin-top: 20px;
    }

    @include respond-to-max-width(991px) {
      padding-top: 20px;
    }

    @include respond-to-between(992px, 1200px) {
      .card-container {
        .product-info .right-container {
          .item-attributes .line-item-attributes.sku_number_cartPage {
            display: inline-block;
            max-width: 160px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

.edit-product-mobile {
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  width: 120px;
  margin-top: 5px;

  @include respond-to-min-width(991px) {
    display: none;
  }
}

.card {
  &.product-info {
    .edit-product {
      margin-top: 0;
    }

    .line-item-quantity {
      max-width: 63.33333%;

      &.limited-quantity {
        opacity: 0;
        pointer-events: none;
      }

      @include respond-to-between(992px, 1200px) {
        max-width: 82.33333%;
      }
    }

    .product-card-footer {
      .row {
        flex-wrap: nowrap;
      }

      .col-8 {
        flex: 0;
      }

      @include respond-to-between(992px, 1200px) {
        margin-left: 60px;
      }
    }

    .line-item-total-price {
      display: flex;
      align-items: flex-end;
      padding-right: 0;
    }

    @include respond-to-between(992px, 1200px) {
      .justify-content-between {
        justify-content: normal !important;
      }
    }
  }
}

.cart-error-messaging.cart-error.error-cart-msg {
  z-index: 3;
}