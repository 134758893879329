$noColor: transparent;
$primary-color: #fff;
$mobile-color-font: #000;
$gutter: 1rem;
$bg-color: #ed3237;
$customMobileBreak: 1341px;

#mainContainer {
  .fixed {
    position: sticky;
    width: 100%;
    z-index: 1024;
    transition: all 0.5s ease-in-out;
    top: 0;
  }

  .header {
    transition: all 0.5s ease-in-out;

    &__main__nav {
      background-color: $bg-color;
      min-height: 100px;
      align-items: center;
      display: flex;

      @include respond-to-max-width($customMobileBreak) {
        min-height: 70px;
      }
    }

    #menuContainer {
      padding: 0;

      #logoContainer{
        min-width: 160px;
      }

      .hidden-sm-down.search.hidden-search-input{
        width: 0;
        height: 0;
        opacity: 0;
      }
    }

    &__container {
      max-width: 100% !important;
      padding: 0 25px;

      @include respond-to-max-width(544px) {
        padding: 0;
      }

      @include respond-to-min-width(1342px) {
        .position-unset {
          position: unset;
        }
      }
    }

    &__main__nav {
      .navbar-header {
        height: fit-content;

        &.brand {
          @include respond-to-min-width(992px) {
            margin-left: 0;
            left: 0;
            width: auto;
            padding-top: 0;

            .logo-home {
              position: relative;
              width: 100%;
            }
          }

          @include respond-to-max-width(992px) {
            width: 100%; /* 64/16 */
            margin-left: 0;
            padding-top: 0;
            left: 0;
          }

          @include respond-to-max-width(768px) {
            height: auto;
            text-align: left;
            display: inline-flex;
            width: fit-content;
            margin: 0;
            padding: 0;
            left: 0;
            flex: 1 0 0;
            max-width: 115px;

            &.without-menu {
              left: 5%;
            }
          }
        }

        @include respond-to-max-width(768px) {
          height: auto;
        }
      }
    }

    &__menu {
      background-color: $noColor;
      border: none;

      @include respond-to-max-width($customMobileBreak) {
        background-color: $primary-color;
        bottom: 0; // White color
        padding: 0;
        position: fixed;
        top: 0;
      }

      @include respond-to-between(1342px, 1500px) {
        margin-left: -50px;
        padding: 0;
      }

      @include respond-to-min-width(1342px) {
        display: block;
      }

      .menu__list {
        .list-link {
          color: $primary-color;
          font-family: $main-font;
          font-weight: bold;
          position: relative;

          @include respond-to-max-width($customMobileBreak) {
            color: $mobile-color-font; // Black color
          }
        }

        &__container {
          border-top: none;

          @include respond-to-max-width($customMobileBreak) {
            .options-container {
              padding-top: 0;
            }

            .container {
              max-width: unset;
              padding-right: 0;
              padding-left: 0;

              .banner-container {
                display: none !important; //sobrescribiendo bootstrap específico que no fue posible borrar por no romper desktop
              }

              .mobile-list-large {
                flex: 100%;
                max-width: 100%;
              }
            }
          }
        }

        .menu__link {
          padding: 40px 0.8rem;
          font-size: 16px;
          padding-left: ($gutter / 2.2);
          padding-right: ($gutter / 2.2);

          &:hover {
            background-color: $noColor;

            .underlineLink {
              border-bottom: 2px solid $primary-color;
              padding-bottom: 2px;
            }
          }

          &:focus {
            background-color: $noColor;

            .underlineLink {
              border-bottom: 2px solid $primary-color;
              padding-bottom: 2px;
            }
          }

          @include respond-to-max-width($customMobileBreak) {
            padding: $gutter ($gutter) + ($gutter / 2);
          }
        }

        &.show {
          .menu__link {
            background-color: $noColor;

            .underlineLink {
              border-bottom: 2px solid $primary-color;
              padding-bottom: 2px;
            }
          }
        }

        @include respond-to-between(769px, $customMobileBreak) {
          border-bottom: 1px solid #eee;
        }
      }

      .header__nav-redirect-moov.menu-redirect {
        border: none;
        padding: 26px 0;
        margin: 0 0 0 ($gutter / 2);

        .-link {
          line-height: 2.9;

          img{
            height: $gutter;
          }
        }
      }

      .header__nav-redirect-dexter.menu-redirect {
        border: none;
        padding: 26px 0;
        margin: 0 0 0 ($gutter / 2);

        .-link {
          line-height: 3.3;
        }
      }
    }

    .carousel-item {
      padding: 0;
    }
  }

  .mobile-title-menu {
    font-size: 18px;

    @include respond-to-min-width($customMobileBreak) {
      display: none;
    }
  }
}

//CSS FOR HEADER AT CART-PAGE
[data-action="Cart-Show"] {
  .header{
    &__cart-page{
      padding: 20px 20px 10px;
      max-height: 100px;

      @include respond-to-max-width($customMobileBreak) {
        padding: 0;
      }
    }

    .header__logo{
      min-width: 115px;

      @include respond-to-max-width(992px) {
        padding-top: $gutter;
        margin-left: -($gutter * 5);
      }
    }

    .carousel-item {
      padding: 0;
    }
  }
}
//////////////////////////////////////////////
.user {
  i {
    font-size: 22px;
  }
}

.header-banner .container .d-flex .content .html-slot-container span {
  color: $black-text;
  font-weight: 600;
}

.minicart {
  div.cart {
    background-color: $background;
    padding-top: 0;
  }

  &-icon {
    color: $icon-color;
    position: absolute;
    top: 6px;
  }

  .minicart-quantity {
    background-color: $black-text;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.6em;
    display: inline-block;
    text-align: center;
    font-size: 0.9rem;
    position: relative;
    top: -1.15em;
    left: 1.3em;
    color: #fff;
    padding: 0;

    .minicart-quantity-d-none {
      background-color: unset;
      border-radius: 50%;
      width: 1.25em;
      height: 1.25em;
      line-height: 1.6em;
      display: inline-block;
      text-align: center;
      font-size: 0.9rem;
      position: relative;
      top: -3.8em;
      left: 1.3em;
      color: #fff;
    }
  }
}

@import "nav-header";
