#refinement-pill-card {
  border-bottom: 1px solid #cacaca;
}

.refinement {
  &-pill {
    &-a {
      display: flex;
      justify-content: space-between;

      &[aria-expanded="true"] .refinement-pill-i {
        rotate: 180deg;
      }

      @include respond-to-max-width(769px) {
        display: none !important;
      }
    }

    &-i {
      margin-left: auto;
      cursor: pointer;
    }

    &-h2 {
      font-size: 15px;
      cursor: pointer;
    }
  }

  &-row {
    display: flex;
    align-items: start;
  }

  .card {
    margin: 0;
  }
}

.search-results .refinements .card .card-header h2 {
  font-size: 16px;
}

.refinements-container {
  max-height: 500px;
}

.card-body.content.value.refinement-scroll {
  max-height: 200px;
  margin-top: 20px;
}

.refinements-container,
.card-body.content.value.refinement-scroll {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 9px;
    box-shadow: inset 7px -4px 12px #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(75deg, #cfcfcf 14%, #d1d1d1 64%);
    border-radius: 9px;

    &:hover {
      background: linear-gradient(13deg, #ccc 5%, #d6d6d6 74%);
    }
  }
}
