.homepage-product-listing {
  .product {
    box-shadow: 0 3px 6px #00000029;

    @include respond-to-max-width($max-mobile-width) {
      width: 150px;
      height: 250px;
    }
  }
}

.product {
  &-tile {
    display: flex;
    flex-direction: column;
    height: 100%;

    .image-container {
      overflow: unset;
      border: 1px solid #cacaca;
      z-index: 10;

      .product-image-container {
        .tile-image.primary-image {
          &:hover {
            opacity: 0;
          }
        }

        .tile-image.second-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: opacity 0.3s ease;
          opacity: 0;

          &:hover {
            opacity: 1;
          }
        }
      }

      .wishlistTile {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 25px;
        height: 25px;
        background: white;
        border-radius: 50%;
        text-align: center;
        line-height: 25px;
        box-shadow: 0 0 11px rgba(0, 0, 0, 0.35);

        span {
          color: $dark-gray;
          width: 25px;
          height: 25px;

          [class*="fa-heart-"] {
            color: $primary-color-2;
          }

          .fa-circle {
            color: $secondary-color-2;
            text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
          }
        }

        @include respond-to-max-width($max-mobile-width) {
          display: none;
        }
      }

      .quickview {
        span {
          width: 25px;
          height: 25px;

          .fa-circle {
            color: #ffffffb3;
            text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }

    .tile {
      &-body {
        $saving-and-installments-font-size-desktop: 14px;
        $saving-and-installments-font-size-mobile: 12px;
        $saving-and-installments-color: #9a9a9a;

        padding: 0;
        margin-top: 10px;
        font-family: $main-font;
        background-color: $background;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-weight: $font-weight-regular;
        color: $saving-and-installments-color;
        min-height: 21px;
        margin-bottom: -7px;

        .color-swatches {
          display: none; // hided for the moment
          padding-bottom: 5px;
          min-height: auto;

          @include respond-to-max-width($max-mobile-width) {
            padding-bottom: 0;
          }

          .swatches {
            img {
              &.swatch {
                width: 20px;
                height: 20px;

                @include respond-to-max-width($max-mobile-width) {
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }
        }

        .pdp-link {
          font-size: 16px;
          height: fit-content;
          min-height: 45px;

          @include respond-to-max-width($max-mobile-width) {
            min-height: 28px;
            max-height: 2em;
            font-size: 12px;
          }

          .link:not(.btn-primary):not(.btn-outline-primary) {
            color: $dark-gray-text;
            line-height: 22px;
            line-height: 18px;
            font-family: $main-font;
            display: block;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;

            @include respond-to-max-width($max-mobile-width) {
              display: -webkit-box;
              line-height: 15px;
              font-size: 12px;
              max-height: 44px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .price {
          font-size: 22px;
          font-weight: bold;
          color: $dark-gray;
          margin-top: 5px;

          .sales {
            padding-right: 10px;
          }

          del {
            font-weight: 400;

            .strike-through {
              color: $regular-light-gray;

              &.list {
                margin-right: 0;
              }
            }
          }

          @include respond-to-max-width($max-tablet-width) {
            font-size: 16px;
          }

          @include respond-to-max-width($max-mobile-width) {
            font-size: 12px;
          }
        }

        &-footer {
          display: none; // hided for the moment
        }

        .installments-container {
          font-family: $main-font;
          font-size: $saving-and-installments-font-size-desktop;
          font-weight: $font-weight-regular;
          color: $saving-and-installments-color;

          .low-stock {
            font-size: 18px;
            font-weight: $font-weight-medium;
            margin-top: 5px;
          }

          @include respond-to-max-width($max-mobile-width) {
            font-size: $saving-and-installments-font-size-mobile;
          }
        }

        @include respond-to-max-width($max-mobile-width) {
          padding-top: 0;
        }

        @include respond-to-min-width($max-mobile-width) {
          padding: 0;
        }

        @import "stamps";
      }
    }
  }

  &-image-container {
    position: relative;

    fieldset {
      position: absolute;
      top: 0;
      left: 20px;
      width: 40px;
      height: 40px;
      background-color: $brand-identity;
      border-radius: 0 0 12px 12px;

      legend {
        font-size: 15px;
        color: white;
        transform: unset;
        display: flex;
        justify-content: center;
        position: relative;
        top: 18px;
        line-height: 0.5px;
        margin: 0;

        @include respond-to-max-width(991px) {
          font-size: 12px;
          top: 14px;
        }
      }

      @include respond-to-max-width(991px) {
        left: 8px;
        width: 30px;
        height: 30px;
      }
    }
  }
}

@include respond-to-max-width(991px) {
  .quickview-newContainer {
    opacity: 1 !important;
    z-index: 10 !important;
    padding: 0;
    margin-top: 0;

    .quickview button {
      height: 25px;
      font-size: 10px;
    }
  }
}

.item-image {
  flex-grow: 0;
  flex-shrink: 0;

  img.product-image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: fit-content;
  }
}
