@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin circle($diameter, $color) {
  // Source:  https://gist.github.com/kogakure/4769904
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: $diameter;
  height: $diameter;
  background: $color;
  -moz-border-radius: $diameter/2;
  border-radius: $diameter/2;
}

@mixin swatch($diameter, $color) {
  @include circle($diameter, $color);
  @include swatch-check-mark();

  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  margin-right: 0.313em;
  position: relative;

  // swatch-filter x mark to undo a filter
  i.fa-times-circle {
    background: $white;
    border-radius: 50%;
    height: 0.75em;
    line-height: 0.8em;
    width: 0.8em;
  }
}

@mixin swatch-check-mark() {
  &.color-value[data-selected=true]::after {
    color: $black;
    content: '\f058';
    display: table-caption;
    font-family: 'FontAwesome';
    font-size: 1.625em;
    left: 0.295em;
    position: absolute;
  }

  &.color-value.selected::after {
    background: $white;
    border-radius: 50%;
    color: $black;
    content: '\f058';
    display: table-caption;
    font-family: 'FontAwesome';
    font-size: 1.625em;
    height: 0.75em;
    left: 0.31em;
    line-height: 0.8em;
    position: absolute;
    top: 0.35em;
    width: 0.8em;
  }
}

// Mixin para manejar los breakpoints
@mixin respond-to-max-width($breakpoint) {
  @media screen and (max-width: #{$breakpoint}) {
    @content;
  }
}

@mixin respond-to-min-width($breakpoint) {
  @media screen and (min-width: #{$breakpoint}) {
    @content;
  }
}

@mixin respond-to-between($breakpoint1, $breakpoint2) {
  @media (min-width: #{$breakpoint1}) and (max-width: #{$breakpoint2}) {
    @content;
  }
}

@mixin font($fontWeight, $fontSize, $lineHeight) {
  font-weight: $fontWeight;
  font-size: $fontSize;
  line-height: $lineHeight;
}

@mixin scrollbar-styles {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 9px;
    box-shadow: inset 7px -4px 12px #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(75deg, #cfcfcf 14%, #d1d1d1 64%);
    border-radius: 9px;

    &:hover {
      background: linear-gradient(13deg, #ccc 5%, #d6d6d6 74%);
    }
  }
}
