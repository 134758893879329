$stamp-font-size-desktop: 14px;
$stamp-font-size-mobile: 12px;

.stamps-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  font-weight: $font-weight-medium;
  min-height: 22px;

  .product-stamp {
    border-radius: 3px;
    padding: 0;
    letter-spacing: 0.84px;
    font-size: $stamp-font-size-desktop;
    text-transform: uppercase;
    font-family: $main-font;
    margin: 2px;
    color: white;
    display: none;

    &.promotion-free-shipping {
      color: #559550;
    }

    &:first-child,
    &:nth-child(2) {
      display: block;
    }

    &:first-child {
      padding-left: 0 !important;
      margin-left: 0 !important;
    }

    @include respond-to-max-width(991px) {
      font-size: $stamp-font-size-mobile;

      &:nth-child(2) {
        display: none;
      }
    }

    @include respond-to-min-width(991px) {
      white-space: nowrap;
    }

    @include respond-to-max-width($max-mobile-width) {
      font-size: $stamp-font-size-mobile;
      margin-bottom: 0;
    }
  }
}

.stamp {
  &-promotion-PRODUCT {
    color: $product-promotion-stamp-color;
  }

  &-promotion-ORDER {
    color: $order-promotion-stamp-color;
  }

  &-promotion-SHIPPING {
    color: $shipping-promotion-stamp-color;
  }

  &-is-launch {
    color: $is-launch-stamp-color;
  }

  &-is-customizable {
    color: $is-customizable-stamp-color;
  }

  &-tag-editable {
    color: $product-editable-stamp-color;
  }

  &-limited-product-sku {
    font-size: 18px;
    font-weight: bold;
    color: $secondary-color-1;
    display: block;
    margin-top: 10px;
    margin-right: 2%;
    border: 1px solid $secondary-color-1;
    border-radius: 0.1875rem;
    padding: 5px;
    max-width: 66%;
    margin-bottom: 3%;

    @include respond-to-max-width($max-mobile-width) {
      max-width: 96%;
    }
  }

  &-user-limited-product {
    font-size: 18px;
    color: $secondary-color-1;
    display: block;
    margin-top: 10px;
    margin-right: 4%;
    font-weight: 500;
  }

  &-shipment-fastShipping {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0;
    color: #559550 !important;
    opacity: 1;
    padding-left: 5px;
  }
}

.card-stamps {
  overflow: hidden;

  &-limitedProduct {
    max-height: 30px;

    @include respond-to-max-width($max-mobile-width) {
      max-height: 20px;
    }
  }
}

.limited-product-sku-cart {
  font-size: 15px;
  color: $secondary-color-1;
  display: block;
  font-weight: bold;
}

.icon {
  &-limitedProduct {
    width: 4%;

    path {
      fill: $secondary-color-1;
    }

    .st9 {
      fill: $secondary-color-1;
    }

    &.face {
      width: 6%;
    }

    &.quick-view {
      width: 6%;
    }
  }

  &-fastShipping {
    top: 590px;
    left: 1006px;
    width: 26px;
    height: 18px;
    opacity: 1;

    path {
      fill: #559550;
    }

    .st0 {
      fill: #559550;
    }
  }
}

.container-fastShipping {
  top: 583px;
  left: 999px;
  height: 32px;
  background: #55955029 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  margin-top: 10px;
  margin-bottom: 5px;
  line-height: 1.8;
  width: fit-content;
  padding-right: 8px;
  font-family: $main-font;
}

.icon header ~ #maincontent .container .product .product-tile .tile-body .card-stamps div.stamps-container span.product-stamp {
  padding: 0 5px;
}
