.modal-dialog.in-store-inventory-dialog {
  .modal-body {
    .store-locator-container {
      .btn-click-collect {
        border-color: #5b2e82;
        background: #5b2e82;
        border-radius: 25px;
      }
    }
  }
}

.modal-header.justify-content-end .modal-title,
.modal-dialog.in-store-inventory-dialog .modal-title {
  font-family: 'Roboto', sans-serif;
  font-size: 1.25rem;
}
