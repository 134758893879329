.home-email-signup {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-top: 15px;
  background-color: #f8f8f8;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  overflow-x: hidden;
  height: unset;
  position: relative;
  border: none;

  .row {
    justify-content: center;

    .col {
      &-sm-12 {
        color: black;

        .newsletter {
          &-title {
            font-size: 24px;
            color: $primary-color-2;
            text-align: center;
            margin-bottom: 0;
            font-weight: bold;
            font-family: $main-font;

            @include respond-to-max-width($max-mobile-width) {
              font-size: 16px;
            }
          }

          &-disclaimer {
            text-align: center;
            color: $primary-color-2;
            font-size: 18px;
            margin: 10px auto 15px;
            font-family: $main-font;
            width: 100%;

            @include respond-to-max-width($max-mobile-width) {
              width: 100%;
              font-size: 12px;
            }
          }
        }

        @include respond-to-max-width($max-mobile-width) {
          padding: 0;
        }
      }

      &-12 {
        font-size: 14px;

        .input-group {
          &-left {
            margin-left: auto;
          }

          &-right {
            margin-top: 0;
          }

          &-contact {
            width: 100%;
            margin-bottom: 10px;

            .form-control {
              height: 40px;
              border: 1px solid $light-black;
              border-radius: 3px 0 0 3px;
              color: $black-text;

              &::placeholder {
                color: #999;
                font-size: 16px;
                font-weight: 400;

                @include respond-to-max-width($max-mobile-width) {
                  font-size: 11px;
                }
              }
            }

            .label_mail {
              &::before {
                content: "*";
                color: #c00;
                margin-right: 3px;
              }
            }

            @include respond-to-max-width($max-mobile-width) {
              width: 100%;
            }
          }
        }

        .invalid {
          &__email,
          &__phone {
            &-home {
              color: red;
              font-size: 13px;
              width: 65%;
            }
          }

          &__email {
            &_home {
              margin-left: 0;
            }
          }

          &__phone {
            &_home {
              margin: auto auto 10px 0;
            }
          }
        }

        .checkbox-contact {
          display: flex;
          margin-left: 27px;

          .form-group {
            .wp-check {
              margin-right: 40px;
            }

            .custom-control {
              &-label {
                padding-top: 4px;

                &::after {
                  cursor: pointer;
                  background-color: white;
                  border: 1px solid black;
                  border-radius: 3px;
                }

                &::before {
                  border: 1px solid #000;
                  background: #f9f9f9;
                  background: linear-gradient(#f9f9f9, #ccc);
                  border-radius: 0.1875rem;
                }
              }

              &-input:checked ~ .custom-control-label::after {
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
                background-color: $primary-color-2;
              }
            }
          }
        }
      }
    }

    .check_contact {
      font-size: 14px;
      text-align: left;

      a {
        font-weight: 500;
        text-decoration: underline;
      }

      @include media-breakpoint-up(sm) {
        text-align: center;
      }
    }

    .input-group-append {
      width: 100%;
      padding: 0 6px;

      > .btn {
        background-color: #5b2e82;
        height: 50px;
        text-transform: uppercase;
        border: none;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0.5px;
        width: 385px;
        margin: 20px auto;
        padding: 10px 50px;
        border-radius: 25px;

        @include respond-to-max-width($max-mobile-width) {
          font-size: 14px;
          width: 100%;
          font-weight: 500;
          letter-spacing: 0;
        }
      }
    }
  }

  @include respond-to-max-width($max-mobile-width) {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: 0;
    margin-right: 0;
  }
}

.page-designer-reference #NewsLetter-btn.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #5b2e82;
  border: none;
}
