#minicart-desktop {
  cursor: pointer;
  height: 39px;
  width: 22px;

  .minicart {
    &-header {
      &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: auto;
        padding: 1rem;
      }

      &-container {
        width: 418px;
        border-bottom: 1px solid #cacaca;
        padding: 0;
        left: -6px;
        position: relative;
      }
    }

    &-close {
      outline: none;
      color: #999;
      font-size: 1.8rem;
      margin: 0;
      display: flex;

      img.close-img{
        width: 16px;
        vertical-align: bottom;
      }
    }
  }
}

.minicart {
  .valid-cart-error {
    position: absolute;
    z-index: 5;
    margin: 0 8px;
    left: 0;
    top: 112px;
  }

  .card {
    border: none;

    &-body {
      padding: 0 9px;
    }
  }

  h1 {
    font-size: 1rem;
  }

  .cart {
    padding-top: 0.625em;
    padding-bottom: 0.625em;
    background-color: $body-bg;
  }

  .remove-btn {
    color: $slightly-darker-gray;
    float: right;
    background-color: white;
    border: none;
    font-size: 1.625em;
    margin-top: -0.313em;
    padding: 0;
  }

  .product-summary {
    margin-right: -0.938em;
    max-height: calc(100vh - 268px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 0.938em;
  }

  .quantity-label {
    font-size: 0.813em;
  }

  .quantity {
    width: 100%;
  }

  .popover {
    top: 100%;
    left: auto;
    right: 0;
    min-width: 23.44rem;
    max-width: 23.44rem;
    min-height: 22.7rem;
    display: block;

    &::before {
      left: auto;
      right: 15px;
    }

    &::after {
      left: auto;
      right: 16px;
    }

    &.show {
      right: 0 !important;
      z-index: 1040;
      display: unset;
      opacity: 1;

      .minicart-footer {
        right: 0;
      }

      @include respond-to-max-width(769px) {
        top: 0 !important;
      }
    }

    &-bottom {
      transition: 0.4s ease-in-out;
      height: 100vh;
      position: fixed;
      top: 0 !important;
      display: block;
      border: 1px solid #cacaca;
      min-width: 420px;
      max-width: 420px;
      right: -422px;
      opacity: 0;

      .text-right {
        width: 100%;
        margin-top: 16px;
        margin-bottom: 16px;
        background-color: white;
        padding: 0 15px;
      }

      @include respond-to-max-width(375px) {
        padding-left: 45px;
      }

      @include respond-to-max-width(385px) {
        padding-left: 40px;
      }

      @include respond-to-max-width(395px) {
        padding-left: 30px;
      }

      @include respond-to-max-width(405px) {
        padding-left: 20px;
      }

      @include respond-to-max-width(415px) {
        padding-left: 10px;
      }

      @include respond-to-max-width(769px) {
        right: 0 !important;
        top: -650px !important;
        width: 100vw;
        max-width: 100vw;
        max-height: 600px;
        border: none;

        .product {
          &-summary {
            height: 360px;

            .row {
              .card {
                margin: 0;
              }
            }
          }

          &-line-item {
            .item-image {
              width: 90px;
              height: 90px;
            }

            .product-line-item-details {
              overflow: unset;

              .item-attributes {
                width: 100%;

                .price-and-quantity {
                  margin-top: 0;
                  padding-bottom: 20px;
                  justify-content: space-between;
                  width: 100%;
                }
              }
            }
          }
        }

        .minicart {
          &-footer {
            position: relative;
            width: calc(100% + 40px);
            right: 5px;
            bottom: 39px;
            border-bottom: 1px solid #cacaca;
          }

          &-header {
            &-title {
              display: flex;
              align-items: baseline;
              padding-top: 16px;

              .col-6 {
                max-width: 87%;
                flex: 70%;
              }
            }

            &-container {
              height: auto;
              width: calc(100% + 40px) !important;
              margin-left: -20px;
              padding-left: 15px;
              border-bottom: 1px solid #cacaca;

              .minicart-header-title {
                display: flex;
                align-items: center;
                padding-top: 16px;

                .col-6 {
                  max-width: 87%;
                  flex: 70%;

                  h1 {
                    margin: 0;
                  }
                }

                .minicart-close {
                  img.close-img {
                    width: 16px;
                    vertical-align: bottom;
                  }
                }
              }
            }
          }
        }

        .cart .line-item-total-price-amount {
          font-size: 16px !important;
        }
      }
    }

    .product-line-item {
      display: flex;
      height: 160px;
      flex-direction: column;

      .item-attributes {
        .line-item-attributes {
          font-size: 14px;
          font-family: 'Roboto';
        }

        .price-and-quantity {
          margin-top: 15px;
          height: 60px;
          align-items: end;
          width: 248px;
        }
      }

      .item-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 7.5rem;
        height: 7.5rem;
        margin-right: 0.8rem;
        border: 1px solid #cacaca;
      }
    }
  }

  .estimated-total {
    margin-top: 0.625em;
  }

  .sub-total {
    font-size: 1em;
    font-weight: 600;

    &-label {
      font-size: 1em;
      font-weight: 600;
    }
  }

  .line-item {
    &-divider {
      margin: 0.625em -0.625em 0.625em -1.325em;
    }

    &-name {
      width: 90%;
    }
  }

  &-mobile-background {
    @include respond-to-min-width(769px){
      display: none !important;
    }

    @include respond-to-max-width(769px) {
      position: fixed;
      background: black;
      height: 100vh;
      padding: 0;
      margin: -150px 0 0 -20px;
      z-index: 1000;
      bottom: 0;
      opacity: 0.7;
      overflow: hidden;
      pointer-events: auto;
      width: calc(100% + 50px);
      transition: 0.4s ease;
    }
  }
}

.container.cart {
  position: relative;
  height: calc(100% - 142px);
  display: flex;
  flex-direction: column;

  @include respond-to-max-width(769px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.add-to-basket-alert {
  display: none !important;
  opacity: 0 !important;
}

.hide-link-med {
  @include media-breakpoint-only(sm) {
    display: none;
  }
}

.hide-no-link {
  @include media-breakpoint-up(md) {
    display: none;
  }

  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.quantity-form.quantity-for-minicart {
  display: flex;
  margin-top: 24px !important;

  .quantity-selector {
    width: 100px;
    height: 37px;
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid #cacaca;
    border-radius: 19px;
    padding: 3px 4px 4px;

    input[type="number"] {
      border: none;
      height: 100%;
      padding: 4px 0 0;
      text-align: center;
      appearance: textfield;
      color: #121212;
      pointer-events: none;
      background: transparent;
      width: 30px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .btn-quantity,
    .btn-qty {
      display: flex;
      color: #fff;
      font-weight: bold;
      border: none;
      min-width: 30px;
      height: 30px;
      border-radius: 50%;
      justify-content: center;

      &:focus {
        outline: none;
      }
    }

    .sub-minicart,
    .add-minicart {
      background-color: #121212;
      margin: 0;
      line-height: 1.8;
    }

    .btn-qty-disabled {
      opacity: 0.1;
      pointer-events: none;
    }

    > .qty {
      background-color: transparent;
      user-select: none;
      color: #121212;
      border: none;
      margin: auto;
      display: flex;

      .btn-quantity,
      .btn-qty {
        + .btn-quantity,
        .btn-qty {
          margin-top: 2px;
        }
      }
    }
  }

  .quantity-selector-title {
    margin-right: 8px;

    p {
      margin: 5px 0 0;
      font-family: 'Roboto', 'Open Sans', sans-serif;
    }
  }
}

@include respond-to-max-width(769px) {
  .card.product-info {
    .line-item-header .line-item-name {
      margin-bottom: 10px;
    }

    .right-container {
      margin-left: 20px;
    }
  }
}

@include respond-to-max-width(992px) {
  #cartItems .row .card-container,
  .totals,
  .card.product-info .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .card.product-info {
    .line-item-header {
      .line-item-name {
        margin-bottom: 0;
      }

      .remove-line-item button {
        top: -5px;
      }
    }

    .right-container {
      padding-left: 7px;

      .quantity-form.quantity-for-minicart {
        margin-top: 4px;
      }
    }
  }

  .line-item-divider {
    margin-top: 36px;
  }
}

@include respond-to-between(769px, 992px) {
  .card.product-info {
    .tablet-container {
      left: 50px;
    }

    .right-container .product-card-footer .line-item-total-price {
      line-height: 0;
      margin-top: 22px;
    }
  }
}

@include respond-to-between(992px, 1201px) {
  .card.product-info .right-container .product-card-footer {
    .line-item-total-price [class*=item-total] {
      position: relative;
      right: 20px;
    }

    .strike-through {
      right: 20px;
      position: relative;
    }
  }
}
