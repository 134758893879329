$variation-attribute-size-disabled-color: #cacaca;
$variation-attribute-size-font-size: 14px;
$variation-attribute-label-font-size-desktop: 16px;
$variation-attribute-label-font-size-mobile: 14px;
$swatch-circle-size: 25px;
$size-chart-font-size-desktop: 14px;
$size-chart-font-size-mobile: 12px;

.attributes {
  padding: 0;
  margin: 0;

  .attribute {
    margin: 0;

    &.quantity {
      display: block;

      &-selector-wrapper {
        margin-bottom: 15px;
      }
    }
  }

  .main-attributes {
    margin-top: 0;
  }

  .color {
    display: inherit;
    margin-bottom: 11px;

    &-attribute {
      outline: none;
    }
  }

  span {
    font-family: $main-font;
    font-weight: $font-weight-medium;

    &.non-input-label {
      font-size: $variation-attribute-label-font-size-desktop;

      @include respond-to-max-width($max-mobile-width) {
        font-size: $variation-attribute-label-font-size-mobile;
      }
    }

    &.swatch-circle {
      width: $swatch-circle-size;
      height: $swatch-circle-size;

      &.selected::after {
        font-size: inherit;
      }
    }
  }

  label {
    font-family: $main-font;
    font-weight: $font-weight-medium;
    font-size: $variation-attribute-label-font-size-desktop;

    @include respond-to-max-width($max-mobile-width) {
      font-size: $variation-attribute-label-font-size-mobile;
    }
  }

  ul {
    &.attribute-size {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin: 0.5rem -5px 0.5rem -5px;
    }

    .variation-attribute-size {
      border: 1px solid #121212;
      border-radius: 3px;
      width: 84px;
      font-size: 18px;
      height: 46px;
      margin: 2px;
      color: #121212;
      display: flex;
      flex: 0 1 84px;

      &.disabled {
        border-color: #cacaca;
        color: #cacaca;
      }

      &.selected {
        color: white;
        background-color: #121212;
      }

      span {
        padding-bottom: 1px;
        padding-left: 5px;
        padding-right: 5px;
      }

      &-values {
        display: flex;
        flex-wrap: wrap;
        margin: -5px;
        margin-left: 2px;

        .modal-li {
          min-width: 105px;
          white-space: nowrap;
        }
      }
    }
  }

  .size-chart {
    cursor: pointer;
    margin-top: 0;
    margin-left: 10px;

    a {
      text-decoration: underline;
      font-size: $size-chart-font-size-desktop;
      font-weight: $font-weight-medium;
      color: black;

      @include respond-to-max-width($max-mobile-width) {
        font-size: $size-chart-font-size-mobile;
        margin: 5px;
      }
    }

    @include respond-to-max-width($max-mobile-width) {
      flex-basis: 100%;
      margin-top: 10px;
      margin-left: auto;
    }
  }

  li {
    font-size: $variation-attribute-size-font-size;
    margin: 5px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-family: $main-font;
      font-weight: $font-weight-regular;
    }

    &.selected {
      border: 1px solid $primary-color-2;
      cursor: not-allowed;
      pointer-events: none;
    }

    &.disabled {
      color: $variation-attribute-size-disabled-color;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

.promotions,
.product-availability {
  display: none;
}
