#refinement-pill-card {
  border-bottom: 1px solid #cacaca;
}

.refinement {
  &.card {
    margin: 0;
  }

  &-pill {
    &-i {
      margin-left: auto;
      cursor: pointer;
    }

    &-a {
      display: flex;
      justify-content: space-between;

      &[aria-expanded="true"] .refinement-pill-i {
        rotate: 180deg;
      }

      @include respond-to-max-width($max-mobile-width) {
        display: none !important;
      }
    }

    &-h2 {
      font-size: 15px;
      cursor: pointer;
    }
  }

  &-row {
    display: flex;
    align-items: start;
  }
}

.search-results .refinements .card .card-header h2 {
  font-size: 16px;
}

.refinements-container,
.card-body.content.value.refinement-scroll {
  max-height: 200px;
  overflow-y: auto;
  margin-top: 20px;

  @include scrollbar-styles;
}

.refinements-container {
  max-height: 500px;
}

.show-more-options-wrapper {
  button {
    display: flex;
    padding: 0;
  }

  span {
    text-align: left;
    margin-left: 5px;
  }
}

@include respond-to-min-width($max-mobile-width) {
  #refinement-bar-id {
    position: sticky;
    top: 125px;
  }

  .grid-header {
    position: sticky;
    top: 30px;
  }
}
