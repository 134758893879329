.container {
  &.receipt {
    padding-top: 30px;

    &-title {
      width: 100vw;
      padding: 0 40px;
      margin-left: calc(-50vw + 50%);
    }
  }
}
