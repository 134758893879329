.custom__popUp-body {
  .newsletter {
    &-disclaimer {
      color: $black-text;
      text-align: center;
      font-weight: 750;
      font-size: 16px;

      @include respond-to-max-width($max-mobile-width) {
        font-size: 14px;
        font-weight: 500px;
      }
    }

    &-text {
      color: $black-text;
      text-align: center;
      font-size: 14px;

      @include respond-to-max-width($max-mobile-width) {
        font-size: 12px;
      }
    }

    &-title {
      color: $black-text;
      text-align: center;
      font-weight: 780;
      font-size: 28px;

      @include respond-to-max-width($max-mobile-width) {
        font-size: 20px;
        font-weight: 500px;
      }
    }
  }
}

.btn-primary {
  &.subscribe-email-popUp {
    background: $btn-subscribe;
    border-color: $btn-subscribe;
    font-size: 12px;
    padding: 5px 25px;
    font-weight: 500;

    @include respond-to-max-width($max-mobile-width) {
      font-size: 12px;
      padding: 3px 15px;
    }
  }
}

.page-designer-reference {
  .form-control {
    border: 1px solid #212121;

    &-custom {
      border: none;
      border-bottom: 1px solid #999;
      border-color: #999;
      border-radius: 0;
      -webkit-box-shadow: 0 0 0 1000px white inset;
      box-shadow: none !important;

      &::placeholder {
        color: #999;
      }

      &:focus {
        border-color: #999;
      }

      &:focus &:not(:placeholder-shown) {
        + .placeholder-label {
          transform: translateY(-30px);
          color: #999;
        }
      }
    }

    @include respond-to-max-width($max-mobile-width) {
      font-size: 12px;
    }
  }

  .modal {
    &-body {
      &.custom__popUp-body {
        padding: 35px;

        .btn-primary:not(:disabled):not(.disabled):active {
          background-color: #5b2e82;
        }

        .invalid__email,
        .invalid__phone {
          color: red;
          font-size: 13px;
          margin-top: 8px;
          margin-left: 1%;
        }

        .phone-invalid {
          margin-bottom: 10px;
        }

        @include respond-to-max-width($max-mobile-width) {
          padding: 30px;
        }
      }

      .col-sm-12 {
        font-size: 14px;

        .input-group-pop {
          margin-bottom: 10px;

          @include respond-to-max-width($max-mobile-width) {
            width: 100%;
          }

          input.form-control {
            &::placeholder {
              color: #999;
              font-size: 16px;
              font-weight: 400;

              @include respond-to-max-width($max-mobile-width) {
                font-size: 11px;
              }
            }
          }

          .label_mail,
          .label_phone {
            &::before {
              content: "* ";
            }
          }
        }

        .checkbox-contact {
          display: flex;
          margin-left: 27px;

          .wp-check {
            margin-right: 40px;
          }

          .form-group {
            .custom-control {
              &-label {
                padding-top: 4px;

                &::after {
                  cursor: pointer;
                  background-color: white;
                  border: 1px solid black;
                  border-radius: 3px;
                }

                &::before {
                  border: 1px solid #000;
                  background: #f9f9f9;
                  background: linear-gradient(#f9f9f9, #ccc);
                  border-radius: 0.1875rem;
                }
              }

              &-input:checked ~ .custom-control-label::after {
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
                background-color: $primary-color-2;
              }
            }
          }
        }

        &-mail {
          margin-top: 20px;
        }

        &-phone {
          margin-top: 30px;
        }
      }

      .input-group-append {
        width: 100%;
        padding: 0 6px;

        > .btn {
          background-color: #5b2e82;
          padding-left: 20px;
          padding-right: 20px;
          border: none;
          font-size: 15px;
          font-weight: bold;
          letter-spacing: 0.5px;
          border-radius: 25px;
          width: 100%;
          margin: 20px auto;
          text-transform: uppercase;
          height: 50px;

          @include respond-to-max-width($max-mobile-width) {
            font-size: 14px;
            width: 100%;
            font-weight: 500;
            letter-spacing: 0;
          }
        }
      }

      .check_contact {
        font-size: 14px;
        text-align: left;

        a {
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }

    &-content {
      width: 110%;
    }
  }

  #modalNewsLetter .modal-footer {
    justify-content: center;

    .custom__close-button {
      color: $custom-link-color;
      font-size: 12px;
      text-decoration: none;
      text-align: center;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
