$product-name-font-size-desktop: 30px;
$product-name-font-size-mobile: 20px;
$product-id-font-size-desktop: 14px;
$product-id-font-size-mobile: 11px;
$product-id-color: #212121;
$add-to-cart-button-background-color: $secondary-color-1;
$add-to-cart-button-color: #fff;
$add-to-cart-button-font-size-desktop: 15px;

$max-mobile-width: 544px;
$min-tablet-width:768px;
$max-tablet-width:992px;
$min-desktop-width: 1342px;

.product {
  &-basic-information {
    @include respond-to-between($max-tablet-width, $min-desktop-width) {
      padding-left: 20px;
    }

    @include respond-to-min-width($min-desktop-width) {
      padding-left: 40px;
    }

    @include respond-to-min-width(1300px) {
      max-width: 570px;
    }
  }

  &-name {
    font-family: $main-font;
    font-size: $product-name-font-size-desktop;
    font-weight: $font-weight-medium;
    border-bottom: none;
    margin: 0;

    @include respond-to-max-width($max-mobile-width) {
      font-size: $product-name-font-size-mobile;
    }
  }

  &-number {
    &.pull-left  {
      font-family: $main-font;
      font-size: $product-id-font-size-desktop;
      font-weight: $font-weight-regular;
      color: $product-id-color;
      padding-top: 0;
      padding-bottom: 0;
    }

    &-rating {
      &.clearfix {
        border: none;
      }

      .ratings {
        display: none;
      }
    }

    @include respond-to-max-width($max-mobile-width) {
      font-size: $product-id-font-size-mobile;
    }
  }

  &-availability {
    display: none;
  }

  &-detail {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    max-width: unset;
    margin: 2em auto;

    hr {
      margin: 13px 0;
    }

    .pre-attributes-separator {
      margin: 13px 0 23px;
    }

    @import "./stamps";
    @import "./attributes";
    @import "./productDescriptionAndDetails";

    .stamps-and-product-id-container {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;

      .stamps-container {
        span {
          &:first-child {
            padding-left: 0;
          }
        }
      }

      @include respond-to-max-width($max-mobile-width) {
        flex-direction: column-reverse;
      }
    }

    .stamps-container {
      .product-stamp {
        padding: 0;
        display: block;
      }
    }

    @import "./pricing";
    @import "./savingAndInstallments";
    @import "./personalization/productPersonalization";
    @import "./giftPackage/giftPackage";
    @import "~instorepickup/product/pdpInstoreInventory";

    .cart-and-ipay {
      .add-to-cart,
      .btn-cart-modal {
        font-family: $main-font;
        height: 50px;
      }

      .add-to-cart {
        border-color: $primary-color-1;
      }

      .btn-cart-modal {
        border-color: #121212;
      }

      @include respond-to-max-width($max-mobile-width) {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-only(sm) {
      .product-name {
        border-bottom: none;
      }
    }

    .pdp-container {
      .prices-add-to-cart-actions {
        padding: 0;
        margin-top: 10px;
        padding-top: 30px;
        display: flex;

        .btn-primary {
          width: 100%;
          color: $add-to-cart-button-color;
          font-size: $add-to-cart-button-font-size-desktop;
          font-weight: bold;

          i {
            &.fa {
              &.fa-shopping-bag {
                display: none;
              }
            }
          }

          &.add-to-cart {
            background-color: $add-to-cart-button-background-color;
          }

          &.btn-cart-modal {
            background-color: #121212;
          }
        }

        .btn-checkout-container {
          padding: 0;

          .btn-container {
            padding: 0;
          }

          @include respond-to-between($min-tablet-width, $max-tablet-width) {
            padding-left: 5px;
          }
        }

        .cart-and-ipay {
          padding: 0;

          @include respond-to-between($min-tablet-width, $max-tablet-width) {
            padding-right: 5px;
          }
        }

        @include respond-to-max-width($min-tablet-width) {
          box-shadow: none;
          flex-direction: column;
          position: unset;

          .cart-and-ipay .btn {
            margin: 0;
          }
        }

        @include respond-to-min-width(992px) {
          align-items: center;
          flex-direction: column;
          justify-content: center;

          button {
            min-width: 100%;
          }
        }
      }

      .cart-and-ipay {
        margin: 0;
      }

      .btn-outline-primary.add-to-wish-list {
        color: #484848;
        padding: 0;
        border: none;
        width: 25px;
        height: 25px;
        background: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 25px;
        box-shadow: 0 0 11px rgba(0, 0, 0, 0.35) !important;

        i {
          font-size: 16px;
        }

        @include media-breakpoint-up(lg) {
          width: 35px;
          height: 35px;
        }
      }
    }

    @import "./socialIcons";
    @import "./paymentsPromotions";
    @import "./clickAndCollectPDP";

    @include respond-to-max-width(543.98px) {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0 20px;
    }

    @include respond-to-min-width(768px) {
      max-width: 100%;
    }

    @include respond-to-min-width(1342px) {
      padding: 0 40px;
    }

    @include respond-to-between(545px, 1341px) {
      padding: 0 45px;
    }
  }
}

.b-product_banner_container {
  margin: 0;
  padding: 0;
  width: 100%;

  a {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

.tooltip-container-click-and-collect {
  font-family: $main-font;
}

.add-to-wishlist {
  &-alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.1875rem;
    z-index: 0;
  }

  &-messages {
    transform: translate(-50%, -50%);
    position: fixed;
    top: 15%;
    left: 50%;
    z-index: 9;
  }
}

.container__banner {
  max-height: 80px;
  display: flex;
  align-items: flex-end;

  .pdp__promotional-banner {
    a {
      text-decoration: none;
    }

    img {
      width: 100%;
    }
  }

  &-mobile {
    @include respond-to-max-width($max-tablet-width) {
      margin-top: 10px;

      img {
        width: 100%;
      }
    }
  }
}

.vfr__container {
  button {
    border: none;
    padding: 0 !important;
    height: unset;
    margin: 0 40px 0 0 !important;
    text-align: left;
    justify-content: left;
    font-weight: bold;
    text-decoration: underline;
    width: fit-content;
    font-size: 14px;
    text-transform: unset;

    &.vfr__button--clean::before {
      filter: brightness(0);
    }
  }
}

@include respond-to-min-width(991px) {
  .image-carousel-div {
    display: none;
  }
}

@include respond-to-max-width(991px) {
  .image-grid-div {
    display: none;
  }
}
