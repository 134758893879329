[data-action="Stores-Find"] {
  .store-locator-container .results-card .card-body .results {
    .card-body {
      .form-check {
        label {
          &::before {
            display: none;
          }
        }

        input[type="radio"] {
          &:checked + label::after {
            display: none;
          }
        }
      }
    }

    .results-quantity {
      display: block;
    }
  }
}

.map-container {
  &.map-canvas-pickup-mobile {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  margin-top: 35px;

  .custom-map-marker-icon{
    background: url(../images/Icon_LocationNew.svg) no-repeat;
    background-size: contain;
    background-position: center;
    filter: $filter-property-map-marker;
    padding: 0.8rem;
  }
}

.bounce-marker{
  animation: bounce 1s ease-in 1;
}

@keyframes bounce {
  20% {
    transform: translateY(-0.6rem);
  }

  40% {
    transform: none;
  }
}

.modal-body {
  padding: 30px;

  .container {
    width: 100%;
    padding: 0;
  }
}

.see-all-stores,
.see-all-stores-pickit {
  display: none;

  @include media-breakpoint-down(md) {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  &.shown-button {
    display: block;

    &::after {
      content: "\f078";
      font-family: "FontAwesome";
      margin-left: 20px;
    }

    &.all-stores-shown::after {
      content: "\f077";
      font-family: "FontAwesome";
      margin-left: 20px;
    }
  }
}

.map {
  &-canvas {
    height: 100%;

    &-pickup-mobile {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    &#map-canvas-pickup-mobile {
      height: 21.5em;

      @include media-breakpoint-down(md) {
        display: block;
      }

      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
  }

  &-container {
    margin-top: 35px;
  }
}

.store-locator {
  .title-container {
    padding-left: 6px;
    margin-top: $margin-big;
    margin-bottom: $margin-small;
    border-bottom: 1px solid $light-gray;

    h1 {
      margin: 0;
      font-size: 20px;
    }
  }

  &-modal {
    .modal {
      &-content {
        max-width: 1140px;
        margin: 5% auto;
      }

      &-title {
        font-family: $main-font;
      }
    }
  }

  &-container {
    .search-form {
      border: none;

      &.card {
        margin-bottom: 20px;
      }

      .card-body {
        padding: 0;

        .store-locator {
          > .row {
            align-items: flex-end;
            margin-bottom: 15px;

            > .col-8.form-group.required {
              margin: 0;
              padding: 0;

              label {
                text-align: left;
                font-weight: $font-weight-medium;
                font-size: 16px;
                margin-bottom: 15px;

                @include respond-to-max-width($max-mobile-width) {
                  font-size: 14px;
                }
              }

              input {
                height: 40px;
                border: 1px solid $light-black;
                width: 101%;
                font-size: 14px;
                border-radius: 2px;
              }
            }

            .btn-storelocator-search {
              background-color: #121212;
              color: $background;
              border: none;
              height: 40px;
              border-radius: 2px;
            }
          }

          .my-location-search {
            display: flex;
            justify-content: center;

            .detect-location {
              margin-bottom: 0;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }

    .results-card {
      border: none;
      margin: 0;

      .card {
        &-header {
          display: none;
        }

        &-body {
          border: none;
          padding: 0;

          .store-locator-no-results {
            text-align: left;
          }

          .results {
            width: 100%;

            &-quantity {
              font-family: $main-font;
            }

            .card-body {
              .form-check {
                border-top: 1px dashed $regular-light-gray;
                border-bottom: 1px dashed $regular-light-gray;

                label {
                  font-family: $main-font;
                  font-size: 14px;
                  font-weight: $font-weight-regular;

                  .display-name {
                    margin-right: 5px;
                  }

                  .arrival-time {
                    color: $text-gray;
                  }

                  &::before {
                    content: " ";
                    display: inline-block;
                    position: relative;
                    top: 30px;
                    right: 2px;
                    width: 17px;
                    height: 17px;
                    border-radius: 11px;
                    border: 1px solid $primary-color-2;
                    background-color: $secondary-color-2;
                  }
                }

                input[type="radio"] {
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  background: $secondary-color-2;
                  cursor: pointer;

                  &:checked + label{
                    &::after {
                      border-radius: 11px;
                      width: 7px;
                      height: 7px;
                      position: absolute;
                      top: 35px;
                      left: 23px;
                      content: " ";
                      display: block;
                      background: white;
                      cursor: pointer;
                    }

                    &::before {
                      background-color: $primary-color-2;
                      cursor: pointer;
                    }
                  }

                  &:not(:checked) + label{
                    &::after,
                    &::before {
                      cursor: pointer;
                    }
                  }
                }
              }

              .select-store-input {
                margin-top: 1.5rem;
                margin-left: 0.75rem;
                font-size: 14px;
              }

              .store-details {
                margin: 0 0 15px 35px;

                .result-number {
                  position: absolute;
                  left: 22px;
                  top: 4px;
                }

                .store-name {
                  font-weight: $font-weight-medium;
                  line-height: 2rem;
                }

                a {
                  text-decoration: underline;
                  line-height: 2rem;
                }
              }
            }
          }
        }
      }
    }

    .select-store {
      border-radius: 25px;
      opacity: 1;
      background: #121212;
      border: none;
      height: 50px;
      width: 100%;
      font-family: $main-font;
      cursor: pointer;

      &:disabled {
        background: #cacaca;
      }

      &:active {
        background: #121212;
      }

      &:focus {
        outline: none;
      }

      &.btn-primary {
        @include respond-to-min-width(544px) {
          margin-top: 1rem;
        }
      }
    }
  }
}
