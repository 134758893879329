
.product-detail {
  .carousel {
    a[class^="carousel-control-"] {
      .icon-prev,
      .icon-next {
        background-color: transparent;
      }

      @include respond-to-max-width($max-mobile-width) {
        display: none;
        width: 1%;
      }
    }

    &-inner {
      .product-image-container {
        position: relative;

        fieldset {
          position: absolute;
          top: 0;
          left: 20px;
          width: 40px;
          height: 40px;
          background-color: $primary-color-1;
          border: none;
          border-radius: 0 0 12px 12px;

          legend {
            transform: unset;
            display: flex;
            justify-content: center;
            position: relative;
            top: 18px;
            line-height: 0.5px;
            font-size: 15px;
            color: white;
            margin: 0;
          }
        }
      }

      @include respond-to-max-width($max-mobile-width) {
        max-height: 580px;
      }
    }

    &-indicators {
      &.custom-top {
        top: 98%;
      }

      li {
        background-color: #21212180;
        height: 11px;
        width: 11px;

        &.active {
          background-color: $light-black;
          height: 14px;
          width: 14px;

          @include respond-to-max-width($max-mobile-width) {
            height: 9px;
            width: 9px;
          }
        }

        @include respond-to-max-width($max-mobile-width) {
          height: 7px;
          width: 7px;
        }
      }

      @include respond-to-max-width($max-mobile-width) {
        &.custom-top {
          top: 87%;
        }
      }
    }
  }
}
