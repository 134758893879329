body {
  background-color: #fff;
}

#maincontent {
  background: $background;
}

h1 {
  font-family: $main-font;
}

#quickViewModal {
  z-index: 9999;

  .fa-shopping-bag {
    visibility: hidden;
  }
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px !important;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;

  &:focus {
    background-color: #eee;
    clip: auto !important;
    clip-path: none;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
  }
}

a,
a:active,
a:focus,
a:hover {
  outline: 0;
}

.close {
  &.close__custom {
    float: none;
    line-height: 1;
    font-size: 0.9rem;
    color: #fff;
    font-weight: 100;
    padding: 9px 50px;
    border-radius: 2px;
    background-color: #000;
    opacity: 1;
    text-shadow: unset;
  }
}

.button__apply-custom {
  :hover {
    border-color: unset;
  }

  .btn-primary.focus,
  .btn-primary:focus {
    box-shadow: unset;
  }

  button:focus,
  a:focus,
  button:visited,
  button:active,
  a:visited,
  a:active {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: unset;
}

.btn {
  &.reset__custom {
    font-size: 0.9rem;
    text-decoration: underline;
    font-weight: 600;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.custom_action_buttons {
  display: flex;
  position: fixed;
  margin: auto;
  left: 0;
  bottom: 0;
  z-index: 100;
  background-color: $background-nav;
  width: 100%;
  padding: 25px 0;
  box-shadow: 0 -2px 3px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 -2px 3px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 -2px 3px 1px rgba(0, 0, 0, 0.1);
}

.wrapper {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-font-smoothing: antialiased;

  .tooltip {
    background: #dbdcda;
    bottom: 100%;
    color: #000;
    font-weight: bold;
    display: block;
    margin-bottom: 15px;
    opacity: 0;
    padding: 10px;
    pointer-events: none;
    position: absolute;
    text-align: center;
    min-width: 95px;
    max-width: 200px;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all 0.1s ease-out;
    -moz-transition: all 0.1s ease-out;
    -ms-transition: all 0.1s ease-out;
    -o-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
    -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);

    &::before {
      bottom: -20px;
      content: " ";
      display: block;
      height: 20px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    &::after {
      border-left: solid transparent 10px;
      border-right: solid transparent 10px;
      border-top: solid #dbdcda 10px;
      bottom: -10px;
      content: " ";
      height: 0;
      left: 50%;
      margin-left: -13px;
      position: absolute;
      width: 0;
    }
  }

  &:hover {
    .tooltip {
      opacity: 1;
      pointer-events: auto;
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
    }
  }

  &.selected {
    .tooltip {
      display: none;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }
  }
}

.lte8 {
  .wrapper {
    .tooltip {
      display: none;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }

    &.selected {
      .tooltip {
        display: none;
      }

      &:hover {
        .tooltip {
          display: block;
        }
      }
    }
  }
}
