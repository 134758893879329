
.promo-code-form {
  .form-group {
    .promo-code-col {
      padding-right: 0;

      .coupon-code-field {
        border: 1px solid $primary-color-2;
        border-radius: 0.1875rem 0 0 0.1875rem;
      }
    }

    .coupon-code {
      font-size: 16px;
      color: $black-text;
      font-weight: $font-weight-medium;
    }

    .promo-code-submit {
      .promo-code-btn {
        background-color: $primary-color-2;
        border-color: $primary-color-2;
        font-size: 15px;
        font-weight: $font-weight-bold;
        height: 38px;
        border-radius: 0 0.1875rem 0.1875rem 0;
      }
    }
  }
}

.totals {
  .leading-lines {
    overflow: hidden;
    margin: 0;

    label {
      background-color: white;
    }

    .start-lines {
      padding: 1px;

      span {
        position: relative;
        background-color: white;
        z-index: 2;
      }

      &::before {
        float: left;
        width: 0;
        white-space: nowrap;
        content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ";
        z-index: 1;
        color: #adadad;
      }
    }

    .end-lines {
      padding: 1px;

      span {
        position: relative;
        background-color: white;
        z-index: 2;
      }
    }
  }

  .cart-button-promotion {
    .checkout-btn {
      background-color: $secondary-color-1;
      border-color: $secondary-color-1;
      border-radius: 25px;
      height: 50px;
      line-height: 2.3;
    }
  }

  .coupons-and-promos {
    .coupon-price-adjustment {
      padding: 10px 15px;
      position: relative;

      .coupon-code {
        font-size: 16px;
        font-weight: $font-weight-bold;
        color: $primary-color-2;
      }

      .coupon-applied {
        position: absolute;
        right: 45px;
        top: 8px;
      }

      .remove-coupon {
        position: absolute;
        right: 0;
        top: -9px;

        span {
          font-size: 32px;
          font-weight: $font-weight-bold;
        }
      }

      .coupon-promotion-relationship {
        list-style: none;
        padding-left: 0;
        font-size: 14px;
        padding-top: 10px;
      }
    }
  }
}
