
$saving-and-installments-font-size-desktop: 14px;
$saving-and-installments-font-size-mobile: 12px;
$saving-and-installments-color: $breadcrumb-active-svg;

.saving-and-installments-container {
  font-family: $main-font;
  font-size: $saving-and-installments-font-size-desktop;
  font-weight: $font-weight-regular;
  color: $saving-and-installments-color;

  .low-stock {
    font-size: 18px;
    font-weight: $font-weight-medium;
    margin-top: 5px;
  }

  @include respond-to-max-width($max-mobile-width) {
    font-size: $saving-and-installments-font-size-mobile;
  }

  .highlight-installment-price-pdp {
    color: $saving-and-installments-color;
    font-weight: bold;
    margin: 5px 0;
  }
}

